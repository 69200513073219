<template>
  <Card>
    <template #title>
      Neueste Aufgaben
    </template>
    <template #content>
      <div v-if="getNewTasks.length === 0">Derzeit keine Aufgaben</div>
      <div v-for="(task, index) in getNewTasks" :key="index" class="task-item">
        <div class="card-grid-container">
          <div>
            <strong>{{ task.number }}</strong>
          </div>
          <div v-if="task.project">
            {{ task.project.employerProjectNumber }}
          </div>
          <div>
            <router-link
              v-if="task.project && task.project.number"
              target="_blank"
              :to="{
                name: 'ProjectEditPage',
                params: { projectNumber: task.project.number },
              }"
              >{{ task.project.customer.lastname }}, {{ task.project.customer.firstname }}
              <i class="fa fa-sm fa-external-link"></i>
            </router-link>
          </div>
          <div>{{ $datetime(task.createdAt) }}</div>
          <div>
            <b-badge :variant="getTaskStateColor(task.taskState)">
              {{ $t(task.taskState) }}
            </b-badge>
          </div>
        </div>
        <div>
          <div>{{ task.description }}</div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
import { mapGetters, mapActions } from 'vuex';
import { getTaskStateColor } from '@/helpers/colors';

export default {
  components: {
    Card,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters(['isLoading', 'getNewTasks', 'getNewTaskCount']),
  },
  methods: {
    ...mapActions(['fetchTasks']),

    /**
     * Load remote table data
     */
    async loadTaskData() {
      await this.fetchTasks({
        page: 0,
        pageSize: 5,
        sortField: 'number',
        sortOrder: -1,
        filters: {
          taskStates: [{ value: 'NEW' }, { value: 'UNSOLVABLE' }, { value: 'IN_PROGRESS' }],
        },
        taskListType: 'NEW_TASKS',
      });

      this.filteredProjects = this.getProjects;
    },
    getTaskStateColor,
  },

  async mounted() {
    await this.loadTaskData();
  },
};
</script>

<style scoped lang="scss">
.task-item {
  background: rgba(0, 0, 32, 0.05);
  margin: 0.5em 0;
  padding: 0.5em;
}

.card-grid-container {
  display: grid;
  grid-template-columns: 35px 75px 1fr 1fr 80px;
  row-gap: 0.5em;
  column-gap: 0.5em;
  margin-bottom: 0.5em;
  justify-items: start;
}
.task-item:hover {
  background: rgba(0, 0, 32, 0.1);
}
</style>
